/* About CSS */
#about-page .page-heading::before {
  content: '01.';
}
#about-page ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    gap: 0px 10px;
    padding: 0px;
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;
}
#about-page ul.skills-list li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    color: var(--liteest-slat);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
}
#about-page ul.skills-list li::before {
    content: "▹";
    position: absolute;
    top: 15%;
    left: 0px;
    color: var(--primary-color);
    font-size: var(--fz-sm);
    line-height: 12px;
}

.about-me {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    align-items: center;
}

.about-welcome {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.about-welcome-quote {
    position: absolute;
    z-index: 2;
    left: 0;
    width: 35%;
    min-width: 400px;
    padding: 65px 60px 0px 70px;
    color: #314251;
    background: #ffffff url(https://www.ahlesunnatpak.com/images/icons/pattern-repeat-x-background-dark.png) top center;
    border-radius: 16px;
    transition: box-shadow .2s;
    box-shadow: 0 0 14px rgba(0,0,0,0.04);  
}

.about-welcome-bottom {
    -ms-flex-align: center;
    align-items: center;
}
.about-welcome-author {
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
}
.about-welcome-sign {
    max-width: 200px;
}
.about-welcome-quote-icon {
    position: absolute;
    top: 35px;
    left: 30px;
}
.about-welcome-photo {
    position: relative;
    z-index: 1;
    width: 74%;
}

.image-container {
    height: 0;
    overflow: hidden;
    position: relative;
    padding-bottom: 75.427673%;
}
.image-container img {
    object-fit: cover;
}

.image-container:not(.ic--ignore-global-round) {
    border-radius: 16px;
}

.image-container.ic--bg {
    background-color: #a6d7f6;
}

.academic-box {
    background: #91db57;
    border-radius: 18px;
    padding: 12px;
    display: grid;
    align-items: center;
    gap: 20px;
    grid-template-columns: min-content 1fr;
}
.academic-box:first-child {
    margin-bottom: 20px;
}
.academic-img {
    border-radius: 18px;
    background: #73a948;
    padding: 12px;
    width: 130px;
}
.academic-details h3 {
    font-size: 16px;
    font-weight: 600;
}
.academic-details p {
    font-size: 14px;
    margin: 0;
}
.skills-details {
    
}
.skills-details h2 {
    font-weight: 600;
}
.skills-details .highliteed-text {
    color: #E42D2D;
}

.skill {
    margin-bottom: 30px;
    width: 100%;
    align-items: center;
    position: relative;
}

.skill h3 {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 16px;
}

.skill h4 {
    float: right;
    font-size: 20px;
}

.skill .skill-bar{
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 8px;
    border-radius: 8px;
    background: #E1EDFB;
}

.skill .fill-bar{
    position: absolute;
    top: 30px;
    left: 0;
    height: 8px;
    border-radius: 8px;
    background: #0969DA;
}

.skill .html{
    animation: html 2s;
    width: 95%;
}

.skill .css{
    animation: css 2s;
    width: 85%;
}

.skill .javascript{
    animation: javascript 2s;
    width: 70%;
}

.skill .php{
    animation: php 2s;
    width: 85%;
}

.skill .react{
    animation: react 2s;
    width: 65%;
}

@keyframes html{
    from{
        width: 0;
    }
    to{
        width: 95%;
    }
}

@keyframes css{
    from{
        width: 0;
    }
    to{
        width: 85%;
    }
}

@keyframes javascript{
    from{
        width: 0;
    }
    to{
        width: 70%;
    }
}

@keyframes php{
    from{
        width: 0;
    }
    to{
        width: 85%;
    }
}

@keyframes react{
    from{
        width: 0;
    }
    to{
        width: 65%;
    }
}

@media (max-width: 991px) {
    .about-me {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        align-items: self-start !important;
    }
    .about-welcome {
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .about-welcome-bottom {
        -ms-flex-pack: center;
        justify-content: center;
    }
    .about-welcome-quote-icon {
        top: 15px;
        left: 20px;
    }
    .about-welcome-quote {
        position: relative;
        width: 100%;
        min-width: unset;
        margin-top: 30px;
        padding: 45px 40px 45px 50px;
        -ms-flex-order: 2;
        order: 2;
    }
    .about-welcome-photo {
        width: 100%;
        -ms-flex-order: 1;
        order: 1;
    }
}

.about-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 26vmax;
    height: 24vmax;
    background-color: rgba(255,255,255,.5);
    border: 2px solid rgba(255,255,255,.8);
}
[data-theme="dark"] .about-icon {
    background-color: rgb(14 14 14 / 50%);
    border: 2px solid rgb(36 36 36 / 80%);
}
.about-icon:hover {
        --x-axis: translateX(calc(-1*var(--parallax-position))) rotateY(calc(-1*var(--angle)));
}
.about-icon span {font-size: 20vmax;    
}

.app__about-left {
    position: relative;
    height: 100%;
}

.app__about {
    padding-bottom: 20px
}
.app__about .sec-title {
    font-size: 6.55vw;
    font-family: Sailec;
    font-weight: bold;
    line-height: 6vw;
    max-width: 700px;
    text-transform: lowercase;
    color: var(--text-theme);
    letter-spacing: -3px;
}

.app__about .sec-title span {
    display: block;
    padding-left: 120px;
    position: relative;
}
.app__about .sec-title span::before {
    position: absolute;
    content: "";
    width: 90px;
    height: 1px;
    left: 0;
    top: 30px;
    background-color: #222;
}

.app__about-left img {
    position: absolute;
    right: 100px;
    bottom: 0;
}

.app__about .sec-text {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    border-radius: 1rem;

}
.app__about .sec-text p {
    font-size: 18px;
    line-height: 1.4;
}
.app__about .about-row {
    margin-top: 20px;
    border-top: 1px solid #CCCEDE;
    border-bottom: 1px solid #CCCEDE;
}

[data-theme="dark"] .app__about .about-row {
    border-top: 1px solid #323232;
    border-bottom: 1px solid #323232;
}
[data-theme="dark"] .app__about .brand-title-wrap {
    border-right: 1px solid #323232
}
.app__about .brand-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
    text-transform: uppercase;
}
.app__about .brand-title-wrap {
    padding: 40px 0;
    border-right: 1px solid #CCCEDE
}

.app__about .brand-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* padding-left: 80px; */
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .app__about .sec-title span {
        padding-left: 100px;
    }
}

@media only screen and (min-width: 350px) and (max-width: 991px) {
    .app__about-left {
        padding-bottom: 0;
    }
    .app__about .sec-title {
        font-size: 12vmax;
        line-height: 12vmax;
    }
    .app__about .sec-title span {
        display: none;
    }
    .app__about .about-row {
        margin-top: 30px;
        padding-bottom: 30px;
        border-bottom: none;
    }
    .app__about .brand-title-wrap {
        border: none;
    }
    .app__about .brand-list {
        flex-wrap: wrap;
        padding: 0 3vw;
        gap: 40px;
    }

    .about-icon {
        position: relative;
        margin-bottom: 40px !important;
    }
    .about-icon span {
        font-size: 16vmax;
    }
}
.column {
    margin: 15px 15px 0;
    padding: 0;
}
.column div {
    position: relative;
    float: left;
    width: 300px;
    height: 200px;
    margin: 0 0 0 25px;
    padding: 0;
}

.column div span {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 24px;
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    /* border: 2px solid var(--primary-color); */
    background-color: var(--lightest-slate);
    top: 10px;
    left: 10px;
    z-index: -1;
    display: block;
    position: absolute;
}
[data-theme="dark"] .column div span {
    /* background-color: var(--navy); */
    background-color: rgb(0 0 0 / 49%);
}

.profile-picture figure {
    width: 300px;
    height: 300px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 24px;
    filter: grayscale(100%) contrast(1);
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
}

.profile-picture figure img {
    position: absolute;
    top: -100px;
}

.profile-picture figure:hover {    
    outline: 0px;
    filter: grayscale(0%) contrast(1);
    transform: translate(-4px, -4px);
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    box-shadow: 0 2px 120px 0 rgba(31,38,103,0.4);
}
.profile-picture figure:hover img {
    filter: none;
    mix-blend-mode: normal;
}

.profile-picture figure:hover + span {
    transform: translate(20px, 20px);
}

@media screen and (max-width: 991px) {
    .profile-picture.column div {
        margin: 0 auto 40px;
        float: none;
        height: auto;
    }
}