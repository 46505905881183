*, ::after, ::before {
  margin: 0;
  box-sizing: border-box;
}

/* Root */
:root {
  /* --primary-color: #1a73e8; */
  --primary-color: rgb(26, 115, 232);
  --primary-color-code: 26, 115, 232;
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;  
  --body-font-color: #495670;
  /* --bg-theme: linear-gradient(90deg, rgb(239, 242, 247) 0%, 7.60286%, rgb(237, 240, 249) 15.2057%, 20.7513%, rgb(235, 239, 248) 26.297%, 27.6386%, rgb(235, 239, 248) 28.9803%, 38.2826%, rgb(231, 237, 249) 47.585%, 48.1216%, rgb(230, 236, 250) 48.6583%, 53.1306%, rgb(228, 236, 249) 57.6029%, 61.5385%, rgb(227, 234, 250) 65.4741%, 68.7835%, rgb(222, 234, 250) 72.093%, 75.7603%, rgb(219, 230, 248) 79.4275%, 82.8265%, rgb(216, 229, 248) 86.2254%, 87.8354%, rgb(213, 228, 249) 89.4454%, 91.8605%, rgb(210, 226, 249) 94.2755%, 95.4383%, rgb(209, 225, 248) 96.6011%, 98.3005%, rgb(208, 224, 247) 100%);   */
  --bg-theme: linear-gradient(77deg,#f5f6ff,#e8e8ff)!important;
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2,12,27,0.7);
  --card-bg-theme: rgba(255,255,255,.5);  
  --card-border-theme: 2px solid rgba(255,255,255,.8);
  --input-theme: rgb(255 255 255 / 40%);
  --input-border-theme: rgba(137, 137, 137, 0.2);
  --white: #ffffff;
  --green: #32b97d;
  --dark-green: #2b9e6a;
  --green-tint: rgba(100,255,218,0.1);
  --pink: #f57dff;
  --blue: #57cbff;    
  --dark-blue: #292654;
  --alert: #f44336!important;

  --modal-width: 380px !important;

  
  --valid-color: #75b798;
  --invalid-color: #ea868f;

  --fz-xxs: 12px;
  --fz-xs: 13px;
  --fz-sm: 14px;
  --fz-md: 16px;
  --fz-lg: 18px;
  --fz-xl: 20px;
  --fz-xxl: 22px;
  --fz-heading: 32px;
}

[data-theme="dark"] {      
  --primary-color: rgb(0, 240, 136);
  --primary-color-code: 0, 240, 136;
  --body-font-color: rgba(248,247,254);    
  --input-theme: rgba(1,1,4,.4);
  --input-border-theme: rgba(255,255,255,.2);
  --card-bg-theme: rgba(0,0,20,.75);
  --card-border-theme: 2px solid rgb(0 0 0 / 6%);
  --text-clip-theme: linear-gradient(#fff,rgba(255,255,255,.7));
  /* --bg-theme: #010314; */
  --bg-theme: rgb(5, 15, 44);
  --divider-bg-color: #0099e5;
  --divider-border-color: #ccc;
}

::selection {
  background: var(--primary-color);
  color: var(--white);
}

/* Scrollbar */
  /* width */
  ::-webkit-scrollbar {
    width: 3px;
    padding-right: 10px;
    -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
    transition: width 2s;
  }
  
  ::-webkit-scrollbar:hover {    
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--lightest-navy); 
    border-radius: 4px;
  }  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--slate); 
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
    width: 12px;
  }

html, body {
  height: 100%;
}

body {
    margin: 0px;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    background: var(--bg-theme);
    color: var(--body-font-color);
    font-family: 'Quicksand';
    font-size: var(--fz-md);
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0px 0px 10px;
    font-weight: 600;
    color: var(--dark-slate);
    line-height: 1.1;
}

.h1, h1 {
    font-weight: 800;
    font-size: 64px
}

 ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
p {
  font-weight: 600;
}
p > a {
  position: relative;
  font-weight: bold;
  background-image: linear-gradient(transparent 50%, var(--primary-color) 50%);
  background-size: auto 180%;
  transition: all 0.3s ease-in-out;
  color: var(--primary-color) !important;
}
p > a:hover,
p > a:focus {    
  color: var(--white) !important;
  background-position-y: 100%;
}

.container {
  padding-left: 25px;
  padding-right: 25px;
}

/* App Global CSS */
.app-frame #tsparticles {
  opacity: 0.2;
}

.parallax-effect {
  --parallax: .1;
  --radius: 1rem;
  --parallax-position: calc(100%*var(--parallax)/(1 + var(--parallax)));
  --angle: calc(-90deg*var(--parallax));
  cursor: pointer;
  aspect-ratio: calc(1 + var(--parallax));
  border-radius: 22px;
  object-fit: cover;
  transform: perspective(40rem) var(--x-axis, rotateY(var(--angle)));
  transition: 0.5s;  
  /* clip-path: inset(0 var(--parallax-position) 0 0 round var(--radius)); */
}

.parallax-effect:hover {    
  /* clip-path: inset(0 0 0 var(--parallax-position) round var(--radius)); */
  --x-axis: translateX(calc(-1*var(--parallax-position))) rotateY(calc(-1*var(--angle)));
}

.text-clip {
    color: transparent;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(#fff,rgba(255,255,255,.8));
    -webkit-background-clip: text;
    background-clip: text;
}
.text-clip-theme {
  background-image: var(--text-clip-theme);
}

.text-bg-purple-pink {
  background-image: linear-gradient(45deg,#3b00ff,#c700ff 50%,#ff4747);
}


a { 
    text-decoration: none;
    background-color: transparent;
    color: var(--primary-color);
}

.app-wrap {
  position: relative;
  /* height: calc(100vh - 80px);
  margin-top: 80px; */
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 50%);  
  transition: all 0.8s ease-in-out;
}

.hide {  
  opacity: 0;
  visibility: hidden;
}

.show {
  opacity: 1;
  visibility: visible;
}

.box {
  padding: 3vw;
  background-color: #ffffff;
  border-radius: 16px;
  transition: box-shadow .2s;
  box-shadow: 0 0 14px rgba(0,0,0,0.04);
}

/* Buttons */
.button,
.ff-submit {
  background: linear-gradient(
    35deg,
    var(--c1, rgb(201 108 249 / 40%) 84.29%),
    var(--c2, rgb(124 35 242 / 40%) 15.75%)
    )
    var(--x, 0) / 200%;
  z-index: 1;
  height: 45px !important;
  border: none;
  color: var(--navy);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 45px;
  letter-spacing: 0.6px !important;
  padding: 0px 30px !important;
  border-radius: 8px !important;
  position: relative;
  text-align: center;
  display: inline-block;
  font-family: "Poppins", sans-serif !important;
  transition: all 300ms ease;
  cursor: pointer;
  text-transform: capitalize !important;
}

.btn-1 {    
    --c1: rgb(124 35 242 / 80%) 15.75%;
    --c2: rgb(201 108 249 / 80%) 84.29%;
    color: var(--white);
}
.btn-2 {    
    --c1: #a1c4fd;
    --c2: #c2e9fb;
}
.btn-3 {    
    --c1: black ;
    --c2:  #434343;
    color: var(--white);
}

.btn-4,
.ff-submit {
  --c1: rgba(0,67,255,.80) 15.75%;
  --c2:  rgba(0,103,255,.80) 84.29%;
    color: var(--white);
}

.button:hover,
#ff-compose .ff-submit:hover {
  --x: 100%;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16) !important;
}
.button:active {
  box-shadow: 0;
}


.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '>>';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button.btn-send span::after {
  content: '📨' !important;
  font-size: 18px;
  top: -3px;
  right: -0px;
}

.button.btn-send:hover span:after  {
  right: -6px;
}

.btn-resume::before {
  content: '📑' !important;
  font-size: 18px;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.btn-effect {
    text-align: center;
    display: flex !important;
    position: relative;
    text-decoration: none;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    align-items: center;
    width: 150px;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s linear 0s;
    margin-right: 17px;
    background: linear-gradient(
    35deg,
    var(--c1, rgb(201 108 249 / 40%) 84.29%),
    var(--c2, rgb(124 35 242 / 40%) 15.75%)
    )
    var(--x, 0) / 200%;
    justify-content: center;
    border: none;
   }
   
   .btn-effect:hover {
    text-indent: -15px;
   }

   .btn-effect:before {
    content: "⇢";
    font-family: quicksand;
    font-size: 15px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 40px;
    transition: all 0.2s linear 0s;
}
.btn-effect:hover:before {
    opacity: 1;
    text-indent: 0px;
}


/* Cards */
.card-background {
    width: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-image: linear-gradient(135deg,rgba(255,255,255,.24),rgba(255,255,255,.08));
    border: 1px solid #f3f3f3;
    border-radius: 1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
    padding: 1px;
    box-shadow: none;
    transition: box-shadow 300ms ease;
}

.card-background:hover {  
    box-shadow: 0 6.67587px 25.869px -1.66897px rgba(123, 104, 238, 0.3);
}
[data-theme="dark"] .card-background {  
    border: 1px solid #0e1327;
}
[data-theme="dark"] .card-background:hover {  
    box-shadow: 0 6.67587px 25.869px -1.66897px rgb(0 240 136 / 20%);
}

.card-background.custom {
    background-image: linear-gradient(333deg,rgba(5,11,31,.16) 60%,#010104);
    border-style: none;
    border-color: #fff;
    border-radius: 1rem
}

.card-background.margin {
    margin-left: 1rem;
    margin-right: 1rem
}

.card-row10_card-small {
    width: 100%;
    background-color: #0e1327;
    background-image: linear-gradient(#090f25 55%,#050b1f);
    border: 1px solid #0e1327;
    border-radius: 8px;
    flex-direction: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    align-items: stretch;
    display: flex;
    position: relative
}

.card-row_card-small-content {
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    padding: 3rem;
    display: flex
}
.hover-state-indicator {
    z-index: 10;
    width: 1px;
    height: 4rem;
    --shady-gradient: rgba(var(--primary-color-code),.7);
    background-image: linear-gradient(transparent,var(--shady-gradient) 26%, var(--primary-color) 46%,var(--shady-gradient) 66%, transparent);
    margin-left: -1px;
    position: absolute;

    transform: translate3d(0px, 252px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 0;
    transform-style: preserve-3d;

    transition: all 300ms ease;
}
.card-background:hover .hover-state-indicator {
    transform: translate3d(0px, 50px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 1;
    transform-style: preserve-3d;
}

 .card-wrapper {
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    background-color: var(--card-bg-theme);
    /* background: url('../src/imgs/card-bg.png') top center no-repeat; */
    border: var(--card-border-theme);
    border-radius: 1rem;
    flex-direction: column;
    flex: 0 35.5rem;
    padding: 2rem
}

.card-wrapper.light.reviews.stretch-vertical {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    justify-content: flex-start;
    display: flex;
    position: static
}

/* Page Header */
.page-heading {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    margin: 10px 0px 40px;
    width: 100%;
    font-size: var(--fz-lg);
    white-space: nowrap;
}
.page-heading::before {
    position: relative;
    margin-right: 10px;
    color: var(--primary-color);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;
}

/* App Elements */
.email { 
    font-size: 24px;
    color: #222;
}
.email:after {
  content: attr(data-email);
}
.mobo { 
    font-size: 24px;
    color: #222;
}
.mobo:after {
  content: attr(data-tel);
}


/* Media Queries */
@media (min-width: 1400px) {
    .container{
        /* max-width: Min(var(--container-max-width),calc(100% - 30px)); */
        padding: 0px 150px;
    }    

    .grid-column {
      grid-template-columns: 1fr 1fr;  
    }
}

@media (max-width: 991px) {
  .app-frame {
    height: auto;
    overflow: unset;
  }
  .page-header {
    min-height: 0vh;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .btn-effect {
    width: 100%;
    margin: 0;
  }
}


/* Shapes */
.shape-group {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
.shape-group .shape {
  left: 0;
  position: absolute;
  z-index: 9;
  bottom: 0;
}
.shape.shape-1 {
    top: 25%;
    z-index: 0;
  }
[data-theme="dark"] {
  .shape {
    opacity: 0.1;
  }
}
.shape.shape-2 {
      left: 7%;
      bottom: 19%;
      z-index: 1;
  }
.shape.shape-3 {
      left: auto;
      right: 5%;
      bottom: 20%;
  }
.shape.shape-4 {
      left: auto;
      right: 15%;
      bottom: 70%;
      z-index: 1;
  }
.shape.shape-5 {
      left: 38%;
      bottom: 60%;
      z-index: 1;
  }
.shape.shape-6 {
      left: 23%;
      bottom: 29%;
      z-index: 1;
  }
.shape-bottom {
  right: 50% !important;
  left: 50% !important;
}

.shape.shape-10 {
  left: auto !important;
  right: 0%;
  bottom: 50%;
  top: 25% !important;
}

/* Lines BG */
.set-line-wrapper,
.set-line-area {  
    overflow: hidden;
}
.set-line-area {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.set-line-area .line-inner {
    width: 100%;
    margin: 0px auto;
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
}

.set-line-area .line-inner .line {
    width: 10%;
    height: 100%;
    box-sizing: border-box;
    border-right: 1px solid rgb(239 239 239);
    position: relative;
    z-index: -1;
}

.set-line-area .line-inner .line:first-child {
    border-left: 1px solid rgb(171 171 171)
}

[data-theme="dark"] .set-line-area .line-inner .line,
[data-theme="dark"] .set-line-area .line-inner .line:first-child {
  border-color: rgba(0, 0, 0, 0.2);
}

.set-line-area .line-inner .line:first-child::before {
    content: "";
    position: absolute;
    top: 0px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line2;
    background: rgb(0, 122, 255);
}

.set-line-area .line-inner .line:first-child::after {
    content: "";
    position: absolute;
    bottom: 0px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line1;
    background: rgb(73, 204, 255);
}

.set-line-area .line-inner .line:nth-child(2)::before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line1;
    background: rgb(122, 25, 255);
}

.set-line-area .line-inner .line:nth-child(2)::after {
    content: "";
    position: absolute;
    top: 0px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line2;
    background: rgb(255, 205, 62);
}

.set-line-area .line-inner .line:nth-child(3)::before {
    content: "";
    position: absolute;
    top: 500px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line3;
    background: rgb(112, 47, 255);
}

.set-line-area .line-inner .line:nth-child(4)::before {
    content: "";
    position: absolute;
    bottom: 400px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line5;
    background: rgb(122, 25, 255);
}

.set-line-area .line-inner .line:nth-child(5)::before {
    content: "";
    position: absolute;
    top: 500px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line3;
    background: rgb(0, 208, 156);
}

.set-line-area .line-inner .line:nth-child(6)::before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line1;
    background: rgb(122, 25, 255);
}

.set-line-area .line-inner .line:nth-child(7)::before {
    content: "";
    position: absolute;
    top: 300px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line4;
    background: rgb(0, 207, 255);
}

.set-line-area .line-inner .line:nth-child(8)::before {
    content: "";
    position: absolute;
    top: 300px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line4;
    background: rgb(0, 207, 255);
}

.set-line-area .line-inner .line:nth-child(9)::before {
    content: "";
    position: absolute;
    top: 300px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line4;
    background: rgb(255, 138, 91);
}

.set-line-area .line-inner .line:nth-child(10)::before {
    content: "";
    position: absolute;
    top: 300px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line4;
    background: rgb(255, 138, 91);
}

.set-line-area .line-inner .line:nth-child(11)::before {
    content: "";
    position: absolute;
    top: 300px;
    right: -2px;
    width: 1px;
    height: 50px;
    animation: 8s ease-out 0s infinite normal none running line4;
    background: rgb(255, 138, 91);
}

@keyframes line1 {
    0% {
        opacity: 1;
        bottom: 0px;
    }

    30% {
        bottom: 30%;
    }

    50% {
        bottom: 50%;
    }

    70% {
        bottom: 70%;
    }

    100% {
        bottom: 100%;
        opacity: 0.5;
    }
}

@keyframes line5 {
    0% {
        opacity: 1;
        bottom: 400px;
    }

    50% {
        bottom: 50%;
    }

    100% {
        bottom: 100%;
        opacity: 0.5;
    }
}

@keyframes line2 {
    0% {
        opacity: 1;
        top: 0px;
    }

    30% {
        bottom: 30%;
    }

    50% {
        bottom: 50%;
    }

    70% {
        bottom: 70%;
    }

    100% {
        top: 100%;
        opacity: 0.5;
    }
}

@keyframes line3 {
    0% {
        opacity: 1;
        top: 500px;
    }

    50% {
        top: 50%;
    }

    100% {
        top: 100%;
        opacity: 0.5;
    }
}

@keyframes line4 {
    0% {
        opacity: 1;
        top: 300px;
    }

    50% {
        top: 50%;
    }

    100% {
        top: 100%;
        opacity: 0.5;
    }
}

@media screen and (max-width: 991px) {  
  .shape-group .shape {
    display: none;
  }

  .app-content-area {
    padding-top: 30px;
    /* height: calc(100vh - 60px);
    overflow-y: auto; */
  }
}



[data-theme="dark"] .app-frame #tsparticles {
  opacity: 1;
}
[data-theme="dark"] p > a {
    color: var(--primary-color) !important;
}

[data-theme="dark"] p > a:hover,
[data-theme="dark"] p > a:focus-visible,
[data-theme="dark"] p > a:hover, 
[data-theme="dark"] p > a:focus  {
  color: var(--dark-navy) !important;
}
[data-theme="dark"] p > a::after {
  background-color: var(--primary-color);
}
[data-theme="dark"] .page-heading {
  color: var(--lightest-slate);
}

[data-theme="light"] .page-heading {
  color: var(--dark-slate);
}

/* Animated Cursor */
[data-theme="light"] .animated-cursor div:nth-child(1){
  border-color: #000!important;
}

[data-theme="light"] .animated-cursor div:nth-child(2){
  background-color: #000 !important;
}

[data-theme="dark"] .animated-cursor div:nth-child(1){
  border-color: #fff !important;
}

[data-theme="dark"] .animated-cursor div:nth-child(2){
  background-color: #fff !important;
}

/* Blob Background */
.blob {
  width: 50px;
  height: 50px;
  background-color: #3498db; /* Use your preferred color */
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.blob-wrap {
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}
.blob-wrap:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  backdrop-filter:blur(100px) ;
  overflow: hidden;
}
@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  50%{
    scale: 1 1.5;
  }
  100%{
    transform: rotate(360deg);
  }
}