  /* Home */
  #home-page.app-content-area {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: calc(100vh - 80vh);
  }

  #home-page .home-hero {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
  }

  #home-page h1 {
    margin: 0px 0px 20px 0px;
    color: var(--body-font-color);
    font-family: "Sailec";
    font-size: var(--fz-md);
    font-weight: 300;
  }

  #home-page h2 {
    margin: 0px 0 20px;
    color: #000;
    font-family: "Sailec";
    font-weight: 300;
    font-size: clamp(40px, 4vw, 80px);
  }

  #home-page h3 {
    margin: 0px;
    background-clip: text;
    color: transparent;
    display: inline;
    line-height: inherit;
    font-style: normal !important;
    font-weight: 300;
    background-image: linear-gradient(90deg, #ba8edc, #f377ab, #9795ff, #5756a5);
    font-family: "Sailec";
    font-size: clamp(40px, 4vw, 80px);
    -webkit-background-clip: text;
  }

  #home-page p {
    margin: 30px 0px 30px;
    max-width: 540px;
    font-weight: 400;
    color: var(--body-font-color);
  }

  /* Buttons */
  .home-btn {
    z-index: 1;
    height: 45px;
    border: none;
    color: var(--navy);
    font-size: 14px;
    font-weight: 500;
    line-height: 45px;
    padding: 0px 30px;
    border-radius: 0px;
    position: relative;
    text-align: center;
    display: inline-block;
    font-family: "Poppins", sans-serif;
    background: linear-gradient(
        34.4deg,
        rgb(var(--c1), 124 35 242 / 40%) 15.75%,
        rgb(var(--c2), 201 108 249 / 40%) 84.29%
      ),
      #ffffff;
    transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    border-radius: 8px;
    overflow: hidden;
  }
  .home-btn:before {
    top: 0px;
    width: 0px;
    left: auto;
    right: 0px;
    z-index: -1;
    bottom: 0px;
    content: "";
    position: absolute;
    background: var(--white);
    transition: width 0.2s linear;
  }
  .home-btn:hover {
    color: var(--navy);
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.12),
      0px 1.6px 3.6px rgba(0, 0, 0, 0.16);
  }
  .home-btn:active {
    box-shadow: none;
  }
  .home-btn:hover:before {
    left: 0px;
    width: 100%;
    right: auto;
  }
  .home-btn.bg-white {
    color: var(--primary-color);
  }
  .home-btn.bg-white:before {
    background: var(--primary-color);
  }
  .home-btn.bg-white:hover {
    color: var(--lightest-slate);
  }
  .home-btn.home-btn-border {
    line-height: 43px;
    color: var(--white);
    background: linear-gradient(
      90deg,
      #7f7dd1,
      #548dd9 100%,
      #548dd9 0
    ) !important;
  }
  .home-btn.home-btn-border:hover {
    color: var(--navy);
  }
  .home-btn.home-btn-border.border-white {
    color: var(--lightest-slate);
  }
  .home-btn.home-btn-border.border-white:before {
    background: var(--lightest-slate);
  }
  .home-btn.home-btn-border.border-white:hover {
    color: var(--white);
  }
  .home-btn svg {
    width: 22px;
    height: 22px;
  }
  .home-btn svg path {
    fill: var(--white);
  }
  .home-btn:hover svg path {
    fill: var(--navy);
  }

  [data-theme="dark"] .home-btn:hover svg path {
    fill: var(--navy);
  }

  [data-theme="dark"] .home-btn-border {
    color: var(--lightest-slate);
  }

  /* Rotating Words */
  .rotating-words-wrapper {
    width: 100%;
    height: 4.08rem;
    display: block;
    position: relative;
    overflow: hidden;
  }

  .rotating-text {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rotating-text {
    transform: translate3d(0, 4.08rem, 0);
    animation: rotate 10s infinite;
    transition-timing-function: cubic-bezier(0.2, 0.5, 0.3, 1);
  }
  .rotating-text:nth-of-type(2) {
    animation-delay: 2.5s;
  }

  .rotating-text:nth-of-type(3) {
    animation-delay: 5s;
  }

  .rotating-text:nth-of-type(4) {
    animation-delay: 7.5s;
  }

  .rotating-text h2 {
    display: inline;
  }

  .heading-style-h2 {
    letter-spacing: -1px;
    font-size: 3.4rem;
    font-weight: 500;
    line-height: 1.2;
  }

  @keyframes rotate {
    /* Text is positioned below the wrap */
    0% {
      transform: translate3d(0, 4.08rem, 0);
    }

    /* Move text up and make it visible */
    2.5% {
      transform: translate3d(0, 0, 0);
    }

    /* Between 2.5% and 25%, nothing changes (text is visible) */
    25% {
      transform: translate3d(0, 0, 0);
    }

    /* Move text up and out of the wrap */
    27.5% {
      transform: translate3d(0, 4.08rem, 0);
    }

    /* Between 27.5% and 99%, nothing changes (text is invisible) */
    99.9999% {
      transform: translate3d(0, 4.08rem, 0);
    }

    /* Move text to it's original position (loop starts over) */
    100% {
      transform: translate3d(0, 4.08rem, 0);
    }
  }

  @media (max-width: 991px) {
    .home-banner-area p {
      max-width: 100% !important;
      font-size: 18px;
    }

    .rotating-words-wrapper {
      height: 2.08rem;
    }
  }

  .experiance-number {
    position: fixed;
    bottom: 2%;
    right: 1.5%;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 100%;
    opacity: 0.6;
  }

  .experiance-number::before {
    content: "";
    position: absolute;
    left: 35px;
    top: 35px;
    right: 35px;
    bottom: 35px;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    -webkit-border-radius: 100%;
  }

  .experiance-number .num {
    position: relative;
    top: 7px;
    font-size: 90px;
    font-weight: 700;
    line-height: 1;
  }

  .experiance-circle-text-label {
    position: absolute;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    animation: 10s linear infinite experianceRotateText;
  }

  .experiance-circle-text-label span {
    position: absolute;
    transform-origin: 0 111px;
  }

  @keyframes experianceRotateText {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @media screen and (max-width: 991px) {
    .experiance-number {
      display: none;
    }

    #home-page.app-content-area {
      height: calc(100vh - 60px);
      padding: 0px;
    }

    #home-page h3 {
      line-height: clamp(40px, 10vw, 80px);
    }
  }

  /* Dark Theme */
  [data-theme="dark"] {
    #home-page h2 {
      color: var(--lightest-slate);
    }
    .experiance-number,
    .experiance-number::before {
      border: 1px dashed rgba(255, 255, 255, 0.3);
    }
  }
  /* light Theme */
  [data-theme="light"] {
  }


   .home-link {
    width: 100%;
   }

   