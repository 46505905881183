/* Theme Switch */
.css-switch {
  margin-left: auto;
  margin-right: 10px;
}

.css-switch .css-switcher {
  display: flex;
    justify-content: center;
    align-items: center;
  }
  .css-switch .css-switcher svg {
    margin: 0px 4px;
    font-size: 12px;    
  }
  .css-switch .css-switcher svg path {
    fill: #fc0;
  }
  .css-switch .css-switcher .tgl {
    display: none;
  }

  .css-switch .css-switcher .tgl-light + .trm-swich {
    margin-bottom: 0;
    box-shadow: inset 0 0 4px -1px rgba(0,0,0,.6);
    background: #02162b;
    border-radius: 2em;
    padding: 2px;
    transition: .4s ease-in-out;
  }

  .css-switch .css-switcher .tgl + .trm-swich {
    outline: 0;
    display: block;
    width: 35px;
    height: 16px;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  .css-switch .css-switcher .tgl:checked + .trm-swich::after {
    left: 50%;
  }
  .css-switch .css-switcher .tgl-light + .trm-swich::after {
    width: 47%;
    border: solid 3px #70ba65;
    border-radius: 50%;
    background: #00283a;
    transition: all .2s ease;
  }
  .css-switch .css-switcher .tgl + .trm-swich::after {
    left: 0;
  }
  .css-switch .css-switcher .tgl + .trm-swich::after {
    position: relative;
    display: block;
    content: "";
    width: 40%;
    height: 100%;
  }


  /* Toggle Switch */
  .tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::-moz-selection, .tgl:after::-moz-selection, .tgl:before::-moz-selection, .tgl *::-moz-selection, .tgl *:after::-moz-selection, .tgl *:before::-moz-selection, .tgl + .tgl-btn::-moz-selection {
  background: none;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
  background: none;
}
.tgl + .tgl-btn {
     outline: 0;
    width: 2.5em;
    height: 1em;
    position: relative;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 40%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
  background: #222222;
}

.tgl-ios + .tgl-btn {
    background: #222222;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    border: 1px solid #222222;
}
.tgl-ios + .tgl-btn:after {
    border-radius: 2em;
    background: #efefef;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
.tgl-ios + .tgl-btn:hover:after {
  will-change: padding;
}
.tgl-ios + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}
.tgl-ios + .tgl-btn:active:after {
  padding-right: 0.8em;
}
.tgl-ios:checked + .tgl-btn {
  background: #efefef;
  border-color: #efefef;
  box-shadow: inset 0 7px 10px 1px rgba(0,0,0,0.3);
}
.tgl-ios:checked + .tgl-btn:active {
  box-shadow: none;
}
.tgl-ios:checked + .tgl-btn:active:after {
  margin-left: -0.8em;
}

.tgl-btn svg {
  position: absolute;
  width: 1em;
}
.tgl-btn svg:nth-child(1) {
  left: 0;
}
.tgl-btn svg:nth-child(2) {
  right: 0;
}
.tgl-btn svg:nth-child(2) path {
  fill: #efefef !important;
}
.tgl-btn svg:nth-child(1) path {
  fill: #222 !important;
}