#notfound {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 5rem;
}

.notfound {
    max-width: 410px;
    width: 100%;
    text-align: center
}

.notfound .notfound-404 {
    height: 280px;
    position: relative;
    z-index: -1
}

.notfound .notfound-404 h1 {
    font-family: 'quicksand';
    font-size: 120px;
    margin: 0;
    font-weight: 900;
    /* position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); */
    background: url('../../imgs/404_bg.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: var(--body-font-color);
    -webkit-mask-image: linear-gradient(180deg,rgba(0,0,0,.66) 33%,transparent 90%);
    mask-image: linear-gradient(180deg,rgba(0,0,0,.66) 33%,transparent 90%);
    filter: blur(2px);
}

.notfound h2 {
    font-family: quicksand,sans-serif;
    color: var(--body-font-color);
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0
}

.notfound p {
    font-family: quicksand,sans-serif;
    color: var(--body-font-color);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 0
}

.notfound a {
    width: 50%;
    margin: 0 auto;
}

@media only screen and (max-width: 767px) {
    #notfound {
        padding: 5rem 2rem;
    }
    .notfound .notfound-404 {
        height:142px
    }

    .notfound .notfound-404 h1 {
        font-size: 112px
    }
    .notfound a {
        width: 100%;
    }
}