/* Experience */
#experience-page .page-heading::before {
  content: "02.";
}

/* Vertical Tabs */
.vertical-tabs {
  display: flex;
}

.tabs-container {
  position: relative;
  z-index: 3;
  width: max-content;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.tab {
  text-decoration: none;
  text-decoration-skip-ink: auto;
  position: relative;
  transition: all 300ms ease-in-out;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0px 20px 2px;
  background-color: transparent;
  color: var(--dark-slate);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 4px;
}
[data-theme="dark"] .tab {
  border-left-color: var(--lightest-navy) !important;
}

.tab.active,
.tab:hover,
.tab:focus-visible {
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.12),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.16);
  color: var(--primary-color);
  outline: 0px;
}

.tab:hover,
.tab:focus {
  background-color: var(--light-navy);
  position: relative;
}
.tab.active {
  color: var(--primary-color);
  background-color: var(--light-navy);
}

.tab:before {
  width: 2px;
  height: 0%;
  transform: translateY(0);
  left: 0;
  content: "";
  position: absolute;
  border-radius: 4px;
  background-color: var(--primary-color);
  transform-origin: top;
  transform: translateY(0) scaleY(0);
  transition: transform 0.3s ease-in;
}
.tab.active:before,
.tab:hover:before,
.tab:focus:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 10;
  height: 45%;
  border-radius: 4px;
  background: var(--primary-color);
  transform: translateY(50%) scaleY(1);
  transform-origin: bottom;
  transition: transform 0.3s ease-out;
}

.tab-content {
  position: relative;
  width: 100%;
  margin-left: 20px;
}

.experience-details {
  width: 100%;
  height: auto;
  padding: 10px 5px;
}

.tab-content h3 {
  margin-bottom: 2px;
  font-size: var(--fz-xxl);
  font-weight: 500;
  line-height: 1.3;
  color: var(--body-font-color);
  display: flex;
  align-items: center;
  gap: 10px;
}

.tab-content h3 .company {
  display: inline-block;
  font-size: 14px;
  padding: 0 0.6em;
  color: #aaa;
}

.tab-content .range {
  margin-bottom: 25px;
  color: var(--light-slate);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
}

.description ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  font-size: var(--fz-sm);
}
.description ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  color: var(--slate);
}
.description ul li::before {
  content: "▹";
  position: absolute;
  left: 0px;
  color: var(--primary-color);
}

[data-theme="light"] .tab.active {
  color: var(--primary-color);
}
[data-theme="light"] .tab.active,
[data-theme="light"] .tab:hover,
[data-theme="light"] .tab:focus {
  background-color: var(--card-bg-theme);
}

@media (max-width: 991px) {
  #experience-page {
    max-width: 100%;
  }
  .tab-contents h3 {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    font-size: var(--fz-xxl);
    font-weight: 500;
    line-height: 1.3;
    color: var(--body-font-color);
  }
  .tab-contents .range {
    font-size: 13px;
  }
  .tab-contents h3 .company {
    display: inline-block;
    font-size: 14px;
    margin-top: 4px;
    color: #aaa;
  }
}

/* Table */
.custom-table {
  margin-top: 40px;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
  border-radius: 4px;
  overflow: hidden;
}
@media screen and (max-width: 580px) {
  .custom-table {
    display: block;
  }
}

.table-row {
  display: table-row;
  background: #f6f6f6;
}
.table-row:nth-of-type(odd) {
  background: #e9e9e9;
}
.table-row.header {
  font-weight: 900;
  color: #ffffff;
  background: var(--primary-color) !important;
}
.table-row.green {
  background: #27ae60;
}
.table-row.blue {
  background: #2980b9;
}
@media screen and (max-width: 580px) {
  .table-row {
    padding: 14px 0 7px;
    display: block;
  }
  .table-row.header {
    padding: 0;
    height: 6px;
  }
  .table-row.header .table-cell {
    display: none;
  }
  .table-row .table-cell {
    margin-bottom: 10px;
  }
  .table-row .table-cell:before {
    margin-bottom: 3px;
    content: attr(data-title);
    min-width: 98px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #969696;
    display: block;
  }
}

.table-cell {
  padding: 6px 12px;
  display: table-cell;
  font-size: var(--fz-sm);
}
@media screen and (max-width: 580px) {
  .table-cell {
    padding: 2px 16px;
    display: block;
  }
}
[data-theme="dark"] .table-row.header {
    background: var(--dark-green) !important;
}
[data-theme="dark"] .table-row {
  background: var(--input-theme);
  color: #ffffff;
}
[data-theme="dark"] .table-row:nth-of-type(odd) {
  background: var(--green-tint);
}

.skills {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: var(--fz-sm);
}

@media (width > 992px) {
  .card-wrapper {
    max-height: 490px;
    overflow-y: auto;
  }
}