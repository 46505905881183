@font-face {
    font-family: 'Sailec';
    src: url('./fonts/Sailec-Light.woff2') format('woff2'),
        url('./fonts/Sailec-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('./fonts/Sailec-Thin.woff2') format('woff2'),
        url('./fonts/Sailec-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('./fonts/Sailec-Medium.woff2') format('woff2'),
        url('./fonts/Sailec-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec-RegularItalic';
    src: url('./fonts/Sailec-RegularItalic.woff2') format('woff2'),
        url('./fonts/Sailec-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sailec';
    src: url('./fonts/Sailec-Bold.woff2') format('woff2'),
        url('./fonts/Sailec-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heartstrong Script Smooth';
    src: url('./fonts/HeartstrongScriptSmooth-Reg.woff2') format('woff2'),
        url('./fonts/HeartstrongScriptSmooth-Reg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heartstrong Script';
    src: url('./fonts/HeartstrongScript-Regular.woff2') format('woff2'),
        url('./fonts/HeartstrongScript-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

