#contact-page .page-heading::before {
  content: "03.";
}
.contact-details {
  grid-template-columns: 1fr 1fr;
  text-align: center;
  gap: 20px
}

/* Form */
.contact-page .box {
  background-color: var(--dark-blue-color) !important;
  color: var(--body-font-color);
}
.contact-form small {
  font-size: 60%;
  display: block;
  margin-bottom: 20px;
  color: var(--body-font-color);
}
.contact-form label {
    font-size: 14px;
    font-weight: 400 !important;
    font-family: 'Quicksand';
    display: block;
    color: var(--body-font-color) !important;
}

.contact-form input,
.contact-form textarea {
    height: auto;
    min-height: 2.75rem !important;
    background-color: var(--input-theme) !important;
    border: 1px solid var(--input-border-theme) !important;
    border-radius: 0.5rem !important;
    margin-bottom: 0;
    padding: 0.5rem 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.6;
    width: 100% !important;
    transition: all 300ms ease;
}

[data-theme="dark"] .contact-form input,
[data-theme="dark"] .contact-form textarea {
  color: rgba(255, 255, 255, 0.7) !important;
}
[data-theme="light"] .contact-form input,
[data-theme="light"] .contact-form textarea {
  color: rgba(0, 0, 0, 0.7);
}
input:focus,
textarea:focus {
  border-color: rgb(155, 155, 155) !important;
  outline: 0;
}
.contact-form ::placeholder {
  font-size: 16px;
  list-style: 12px;
  opacity: 0.6;
}

.contact-form button.submit-btn {
  font-size: 16px;
  background-color: var(--primary-color);
  color: var(--offwhite-color);
}

.contact-form h2 span {
  /* background: linear-gradient(90deg, #0ea5ea, #0bd1d1 51%, #0ea5ea) var(--x, 0) /
    200%;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent; */
  color: var(--primary-color);
}

.hidden-field {
  opacity: 0;
  position: absolute;
  bottom: 0;
}

.info-details {
  padding: 10px 0;
  align-items: center;
  gap: 10px;
  grid-template-columns: min-content max-content;
}

/* .info-details:hover {
    box-shadow: 0 4px 14px rgba(0,0,0,0.1);
} */

.info-icon svg {
  width: 28px;
  height: 28px;
}

.info-icon svg.age path {
  fill: #ffc120;
}

.info-icon svg.email {
  fill: #007dc6;
}

.info-icon svg.call {
  fill: #f47421;
}

.info-icon svg.location {
  fill: #292d32;
}

.info-text {
  display: flex;
  justify-content: center;
}

.info-text {  
  font-size: 1em !important;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 2px;
}
.info-text a {
  font-size: inherit !important;
  color: var(--body-font-color);
}
.chat-icon {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.chat-icon > svg {
  width: 10rem;
  height: 10rem;
  stroke: var(--body-font-color);
  fill: var(--body-font-color);
}

/* Google Forms */
.react-tel-input .form-control {
  padding-left: 46px !important;
}

.invalid-msg,
.valid-msg {
  display: inline-block;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.invalid-msg {
  color: var(--invalid-color) !important;
}

.valid-msg {
  color: var(--valid-color) !important;
}

/* Modal Window */
.modal-dialog {
  max-width: var(--modal-width) !important;
}
.modal-backdrop {
  backdrop-filter: blur(10px) !important;
  --bs-backdrop-bg: rgba(0, 0, 0, 20%) !important;
  opacity: inherit !important;
}
.modal-content {
  border-radius: 24px !important;
  text-align: center;
  box-shadow: 0 61.6px 115.6px 0 rgba(0,0,0,.22), 0 0 41.4px 0 rgba(0,0,0,.18);
  border: none;
}
.modal-content svg {
  width: 180px;
}
.modal-content h2 {
  margin: 24px 0;
  font-family: Sailec;
  font-weight: 300;
  color: var(--green);
}
.modal-content p {
  font-weight: 600;
  color: var(--navy);
  margin: 0 0 24px;
}
.modal-content .btn-primary {
  border-radius: 24px;
  background-color: var(--green);
  border-color: var(--green);
  color: var(--white);
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  padding: 0.8em 2em;
}
:not(.btn-check)+.btn:active {
  background-color: var(--dark-green);
  border-color: var(--dark-green);
  box-shadow: inset ;
}
:not(.btn-check)+.btn:active:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba(49,132,253,.5);
}
.modal-content .btn-primary font {
  line-height: 1;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
    margin: 24px;
  }
  .modal-content svg {
    width: 110px
  }
  .modal-content p {
    margin: revert;
  }
}

@media (max-width: 771px) {  
  .contact-details {
    grid-template-columns: 1fr;
  }
}

.react-tel-input .flag-dropdown,
.react-tel-input .selected-flag  {
  border-radius: 0.5rem 0 0 0.5rem !important;
}

.country-name {
  color: var(--dark-blue);
}