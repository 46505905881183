/* Header */
.app-header {
  z-index: 99;
  padding: 0 15px;
  top: 0;
  left: 0;
  right: 0;
  position: relative;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0;
}

/* Desktop Nav */
.desktop-nav {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.desktop-nav ol {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.desktop-nav ol li {
    margin: 0px 15px;
    position: relative;
    counter-increment: item 1;
    font-size: var(--fz-lg);
}

.desktop-nav ol li a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: var(--transition);
    padding: 10px 0;
    position: relative;
}


.desktop-nav ol li a::before {
    content: "0" counter(item) ".";
    margin-right: 5px;
    color: var(--primary-color);
    font-size: var(--fz-sm);
    text-align: right;
}
.desktop-nav ol li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: var(--primary-color);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.desktop-nav ol li a:hover::after{
  transform-origin: left;
  transform: scaleX(1);
}

.button-menu {
  z-index: 99;
  padding: 0;
  border: none;
  background: transparent;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  position: relative;
}
.bars {
  width: 36px;
  display: flex;
  flex-direction: column;
  align-items: self-end;
  position: relative;
  color: #000;
}
.bars .bar1 {
  width: 28px;
}
.bars .bar1,
.bars .bar2,
.bars .bar3 {
  height: 3px;
  display: block;
  position: relative;
  transition: 0.3s;
  margin-bottom: 4px;
  background-color: #fff;
}

.btn-menu-title {
  color: #fff;
  font-size: 16px;
  display: none;
}
.menu-visible .btn-menu-title  {
  color: #fff !important;
}
.button-menu .bar2 {
  width: 36px;
  content: "";
}
.button-menu .bar3 {
  width: 22px;
  content: "";
  margin-bottom: 0;
}
.menu-visible .button-menu .bars {
  align-items: center;
}
.menu-visible .button-menu .bar1 {
  -webkit-transform: rotate(45deg) scaleX(0.9);
  transform: rotate(45deg) scaleX(0.9);
  -webkit-transform-origin: center;
  transform-origin: center;
  top: 4px;
}
.menu-visible .button-menu .bar2 {
  display: none;
}
.menu-visible .button-menu .bar3 {
  -webkit-transform: rotate(-45deg) scaleX(0.9);
  transform: rotate(-45deg) scaleX(0.9);
  -webkit-transform-origin: center;
  transform-origin: center;
  margin-bottom: 0;
  top: -2px;
}

.menu-visible .button-menu .bar1,
.menu-visible .button-menu .bar3 {
  width: 100%;
  background-color: #fff !important;
}


/* Menu */
.menu-wrapper {
  position: fixed;
  width: 50%;
  z-index: 999;
  top: 0;
  bottom: 0;
  right: -100%;
  display: flex;
  align-items: center;
  background-color: #111827;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.menu-wrapper.menu-visible {
  right: 0%;
}
.menu-visible {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  visibility: visible;
}

.menu-wrapper .button-menu {
  position: fixed;
  top: 29px;
  right: 20px;
}

.menu-wrapper > div,  
.menu-wrapper > nav {
  margin-top: auto;
}
.navbar {
    display: flex;
    color: #fff;
    font-size: 40px;
    font-family: 'Sailec';
    font-weight: 100;
    line-height: 1.4;
    gap: 24px;
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-items: start;
}
.navbar a {
  color: #fff;
  transition: 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition-timing-function: ease-in;
  transform: translateY(100%);
  opacity: 0;
}
.navbar a:after {
  content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #000;
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transition: transform .4s ease-out;
    -webkit-transition: transform .4s ease-out;
    transform-origin: bottom right;
}
.navbar a.is-opened {
  opacity: 1;
  transform: translateY(0) rotate(0);
  pointer-events: auto;
  transition-timing-function: ease;
  transition-duration: 0.8s;
}
.navbar a.is-opened:nth-of-type(1) {
    transition-delay: 0.5s;
}
.navbar a.is-opened:nth-of-type(2) {
    transition-delay: 0.6s;
}
.navbar a.is-opened:nth-of-type(3) {
    transition-delay: 0.7s;
}
.navbar a.is-opened:nth-of-type(4) {
    transition-delay: 0.8s;
}
.navbar a.is-opened:nth-of-type(5) {
    transition-delay: 0.9s;
}

.navbar a:hover,
.navbar a.active,
.menu-email:hover  {
  text-decoration: none;
  color: var(--primary-color);
}

.navbar a:hover:after {
  background-color: var(--primary-color);
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar a svg {
  zoom: 2;
  margin-right: 10px;
}
.navbar a svg .email-rect-1,
.navbar a svg .email-rect-2 {
  fill: transparent;
  stroke: #fff;
}

.menu-email {
    font-size: 16px !important;
    font-weight: 400;
    color: rgba(255,255,255,0.5) !important;
}

.mobile-social-menu {
  display: none;
}


@media (max-width: 991px) {  
  .app-header {
    height: 80px;
    top: 0;

    /* -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    background-color: rgba(5,5,46,.25);
    border: 1px solid rgba(255,255,255,0.08); */
  }
  .home-banner-area {
    padding: 0 !important;
    max-width: 100% !important;
  }
  .home-banner-area h1 {
    font-size: 32px !important;
  }
  .home-banner-area h2 {
    font-size: 24px !important;
  }
  .menu-wrapper {
    flex-direction: column;
    align-items: start;
    width: 80%;
    justify-content: center;
    z-index: 999;
  }
  .navbar {
    font-size: 24px;
  }
  

  .menu-wrapper .button-menu {
    position: fixed;
    top: 24px;
    right: 21px;
  }

  .inside-page {
    padding-top: 20vw;
  }  
}

.header-logo a > .logo {
  height: 60px;
}

[data-theme="light"] .header-logo a > .logo path {
      fill: var(--white) !important;
}
[data-theme="dark"] .header-logo a > .logo path {
      fill: var(--white) !important;
}

[data-theme="light"] .desktop-nav ol li a {
  color: var(--dark-slate)
}


[data-theme="dark"] .desktop-nav ol li a {
  color: var(--lightest-slate)
}



[data-theme="light"] .desktop-nav ol li a.active,
[data-theme="dark"] .desktop-nav ol li a.active {
    color: var(--primary-color);
}


/* Logo BG Animation */
.bg-blob{
    background-image: linear-gradient(120deg, #34e0f0 0%, #b400ff 100%);
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    width: 72px;
    height: 72px;
    animation: morph 3s linear infinite;
    transform-style: preserve-3d;
    outline: 1px solid transparent;
    will-change: border-radius;
    position: absolute;
    top: -5px;
    z-index: -1;
    left: 0;
}
.bg-blob:before,
.bg-blob:after{
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0; top: 0;
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  box-shadow: 5px 5px 89px rgba(0, 102, 255, 0.21);
  will-change: border-radius, transform, opacity;
  animation-delay: 200ms;
  background-image: linear-gradient(120deg, rgba(var(--primary-color-code),.55) 0%, rgba(var(--primary-color-code),.7) 100%);
}

.bg-blob:before{
  animation: morph 3s linear infinite;
  opacity: .21;
  animation-duration: 1.5s;
}

.bg-blob:after{
  animation: morph 3s linear infinite;
  animation-delay: 400ms;
  opacity: .89;
  content: "";
  line-height: 120px;
  text-indent: -21px;
}

@keyframes morph{
  0%,100%{
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  34%{
      border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform:  translate3d(0,5px,0) rotateZ(0.01deg);
  }
  50%{
    opacity: .89;
    transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  67%{
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
    transform: translate3d(0,-3px,0) rotateZ(0.01deg);
  }
}

@keyframes fadeIn{
  100%{
    transform: scale(1.03);
    opacity: 0;
  }
}