/* Floating Social Links */
.social-bar {
    bottom: 0;
    left: 20px;
    width: 20px;
    height: 200px;
    position: fixed;
    z-index: 9;
}

.social-bar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    bottom: 50px;
}

.social-bar ul li {
  display: block;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  transition: all 300ms;
}

.social-bar ul li:hover {
  transform: scale(1.5, 1.5);
}

.social-bar ul li a, 
.social-bar p,
 .btn-menu-title {
  color: var(--body-font-color);
}
.social-bar ul li a svg path {  
  fill: var(--body-font-color);
}
 .social-bar p:after,
 .bars .bar1, 
 .bars .bar2, 
 .bars .bar3 {
  background-color: var(--body-font-color);
}

.social-bar p {
    bottom: -20px;
    left: -24px;
    width: 70px;
    height: 20px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.2;
    white-space: nowrap;
    position: relative;
    transform: rotate(-90deg);
}
.social-bar p:after {
  top: 9px;
  right: -48px;
  width: 40px; 
  height: 1px;
  content: "";
  display: block;
  position: absolute;
}

/* Media Queries */
@media (max-width: 991px) {    
  .social-bar {
    opacity: 0;
    transform: translateY(0) rotate(0);
    pointer-events: auto;
    transition-timing-function: ease;
    transition-duration: 0.8s;
  }
  .app-header.menu-visible .social-bar {
    opacity: 1;
    transition-delay: 1s;
  }

  .mobile-social-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .mobile-social-menu .social-bar {   
    position: unset;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: auto;
    width: auto;
    gap: 50px;
    opacity: 1;
  }
  .mobile-social-menu .social-bar ul {
    display: flex;
    gap: 10px;
    bottom: 0;
    order: 1;
  }
  .mobile-social-menu li {
    padding: 0;
    display: flex;
    margin: 0;
    line-height: 1.4;
    backface-visibility: hidden;
  }
  .mobile-social-menu li a {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 400;
    color: #fff !important;
    display: block;
    will-change: transform;
  }
  .mobile-social-menu li a:before {
    content: '';
    display: inline-flex;
    height: 1px;
    width: 0;
    background: #fff !important;
    margin-right: 0px;
    margin-left: 0;
    transition: all 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
  }
  
  .mobile-social-menu li a:hover:before {
    width: 12px;
    margin-right: 5px;
  }
  
  .mobile-social-menu .social-bar p {
    transform: rotate(0deg);
    margin: 0;
    position: inherit;
    line-height: normal;
  }
  .mobile-social-menu .social-bar p::after {
    right: -35px;
  }
}