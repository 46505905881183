/* Lite Theme CSS */  
[data-theme="light"] p > a,
[data-theme="light"] p > a:hover,
[data-theme="light"] p > a:focus-visible,
[data-theme="light"] p > a:hover, 
[data-theme="light"] p > a:focus   {
    color: var(--light-navy);
}
[data-theme="light"] p > a::after {
  background-color: var(--light-navy);
}